var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"OQ4Ah5tsnvlzvjt-mcZ7F"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
    ],
    dsn: process.env.SENTRY_DSN,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    integrations: [
      new CaptureConsoleIntegration({
        // array of methods that should be captured
        // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
        levels: ["error"],
      }),
    ],
    beforeSend(event) {
      // TODO: Fix this error. Ingoring FedCM error
      const ignoredMessages = [
        "Your client application may not display the Google One Tap in its default position. When FedCM becomes mandatory, One Tap only displays in the default position. Refer to the migration guide to update your code accordingly and opt-in to FedCM to test your changes.",
        "FedCM get() rejects with NetworkError: Error retrieving a token.",
        "FedCM get() rejects with NotAllowedError: Only one navigator.credentials.get request may be outstanding at one time.",
        "Error retrieving a token.",
        "FedCM get() rejects with AbortError: Only one navigator.credentials.get request may be outstanding at one time.",
        "FedCM get() rejects with NotSupportedError: The user agent does not support public key credentials.",
      ];
      if (
        event.message &&
        ignoredMessages.some((msg) => event.message.includes(msg))
      ) {
        return null;
      }

      return event;
    },
  });
}
